import {runInAction} from 'mobx';
import {Country} from '../country/country.model';
import {Contact} from '../company/company.model';
import {action, computed, observable} from '@myshared/mobx-connector';
import {getEnvByUrl} from "@myshared/utils";
import {I18NextService} from "angular-i18next";

export enum AuthState {
  UNAUTHORIZED,
  KEYCLOAK_AUTHORIZED,
  AUTHORIZED,
  FAILED
}

export class Tenant {
  id: number;
  name: string;
  currency: string;
  countryCode: string;

  public static fromJson(json: any): Tenant {
    const tenant = new Tenant();
    tenant.id = json.id;
    tenant.name = json.name;
    tenant.currency = json.currency;
    tenant.countryCode = json.country_code;

    return tenant;
  }
}

export const SUPPORTED_NUMBER_ALLOCATION_WHITELIST = ['DE'];

export class KeycloakUser {
  public sub: string;
  public email_verified: boolean
  public name: string;
  public given_name: string;
  public family_name: string;
  public email: string;
  public phone_number?: string;
  public phone_number_verified?: boolean;

  public static fromJson(json: any): KeycloakUser {
    const u = new KeycloakUser();
    u.email = json.email ?? '';
    u.name = json.name ?? '';
    u.given_name = json.given_name ?? '';
    u.family_name = json.family_name ?? '';
    u.sub = json.sub ?? '';
    u.email_verified = json.email_verified ?? false;
    u.phone_number = json.phone_number ?? undefined;
    u.phone_number_verified = json.phone_number ?? undefined;

    return u;
  }

  @computed
  public get hasPhoneNumber(): boolean {
    // We only need to check phone number verified, if we do not have a phone number, this is always undefined or false
    return this.phone_number_verified;
  }
}

export class CurrentUser extends Contact {

  public lang: string;
  public country: Country;

  public companyIsPartner: boolean;
  public companyIsCsp: boolean;
  public companyIsPC20Csp: boolean;
  public prepayment_only: boolean;
  public company_website_profile: string;
  public companyTags: [string];
  public prohibitOldProducts: boolean;
  public companyId: string;
  public trunkEnvironment: TrunkEnvironment;
  public tenant: Tenant;
  public accounts: CurrentUser[];

  constructor() {
    super();
  }

  public static fromJson(json: any): CurrentUser {
    const c = new CurrentUser();
    Object.assign(c, Contact.fromJson(json));
    c.lang = json.lang || '';
    c.companyIsPartner = !!json.company_is_partner;
    c.companyIsCsp = !!json.company_is_csp;
    c.companyId = json.company_id || '';
    c.country = Country.fromJson(json.country || {});
    c.trunkEnvironment = TrunkEnvironment.fromJson( json.trunk_environment || {});
    c.company_website_profile =  json.company_website_profile || '';
    c.prepayment_only = !!json.prepayment_only;
    c.companyTags = json.company_tags || [];
    c.prohibitOldProducts = !!json.prohibit_old_products;
    c.companyIsPC20Csp = !!json.company_is_pc20_csp ?? false;
    c.tenant = Tenant.fromJson(json.tenant ?? {});
    c.accounts = (json.accounts && json.accounts.length > 0) ? json.accounts.map(r => CurrentUser.fromJson(r)) : [];
    return c;
  }
}

export class TrunkEnvironment {

  public trunkCountryPrefix: string;
  public trunkCountryLocationIndependentPrefix: string;
  public tenantCountryCode: string;
  public disabled: boolean;

  public static fromJson(json: any): TrunkEnvironment {
    const te = new TrunkEnvironment();
    te.trunkCountryPrefix = json.trunk_country_prefix ?? '';
    te.trunkCountryLocationIndependentPrefix = json.trunk_country_location_independent_prefix ?? '';
    te.tenantCountryCode = json.tenant_country_code ?? '';
    te.disabled = json.trunk_disabled ?? true;
    return te;
  }

  @computed public get hasTrunkAccess(): boolean {
    return !!this.trunkCountryPrefix && !!this.trunkCountryLocationIndependentPrefix;
  }

  /**
   * Restricts the AT tenant customer to go through the complete process of this workflow.
   * After selecting new or port number, AT tenant will get a hint to contact sales team.
   */
  @computed public get isNumberAllocationSupported(): boolean {
    return SUPPORTED_NUMBER_ALLOCATION_WHITELIST.includes(this.tenantCountryCode.toUpperCase());
  }

  @computed public get translationCode() {
    if (!!this.tenantCountryCode && (this.hasTrunkAccess || this.disabled)) {
      return `-${this.tenantCountryCode.toLowerCase()}`;
    }

    return '-int';
  }
}

export class AccountModel {
  @observable isInitialized: boolean;
  @observable isResetPassword: boolean;
  @observable isInvite: boolean;
  @observable authState: AuthState;

  @observable currentUser: CurrentUser;
  @observable keycloakUser: KeycloakUser;
  @observable _isSSOUser: boolean;

  constructor() {
    this.initialize();
    runInAction(() => {
      this.currentUser = CurrentUser.fromJson({});
      this.keycloakUser = KeycloakUser.fromJson({});
    });
  }

  @action initialize() {
    this.currentUser = new CurrentUser();
    this.authState = AuthState.UNAUTHORIZED;
  }

  @computed
  public get isMypascomAdmin(): boolean {
    return this.currentUser.mypascom_account === 'admin';
  }

  @computed
  public get isInCompany(): boolean {
    return this.currentUser.companyId !== '';
  }

  @computed
  public get isAuthorized(): boolean {
    return this.authState === AuthState.AUTHORIZED;
  }

  @computed
  public get isAuthorizationFailed(): boolean {
    return this.authState === AuthState.FAILED;
  }

  @computed
  public get hasSMSValidationTag(): boolean {
    return !!this.currentUser.companyTags.find(c => c === 'my-2fa-beta');
  }

  @action setUnauthenticated() {
    this.isInitialized = true;
    this.authState = AuthState.UNAUTHORIZED;
    this.setCurrentUser({});
  }

  @action setVerificationNeeded() {
  }

  @action setVerificationFailed() {
  }

  @action setAuthorizationFailed() {
    this.isInitialized = true;
    this.authState = AuthState.FAILED;
  }

  @action setKeycloakAuthorized() {
    this.isInitialized = true;
    this.authState = AuthState.KEYCLOAK_AUTHORIZED;
  }

  @action setAuthorized(u: CurrentUser) {
    this.isInitialized = true;
    this.authState = AuthState.AUTHORIZED;
    this.currentUser = u;
  }

  @action setResetPassword() {
    this.isResetPassword = true;
  }

  @action setInvite() {
    this.isInvite = true;
  }

  @action setCurrentUser(u) {
    this.currentUser = CurrentUser.fromJson(u);
  }

  @action setKeycloakUser(json: any) {
    this.keycloakUser = KeycloakUser.fromJson(json);
  }

  @computed get hasTrunkAccess(): boolean {
    return this.currentUser.trunkEnvironment.hasTrunkAccess
  }

  @action setSSOUser() {
    this._isSSOUser = true;
  }

  @computed
  public get isSSOUser(): boolean {
    return this._isSSOUser;
  }

  public partnerMediaLink(i18next: I18NextService) {
    const trunkEnvironment = this.currentUser.trunkEnvironment;

    const env = getEnvByUrl()

    let envExtension = '-dev';
    if (env.prod) {
      envExtension = '';
    } else if (env.test) {
      envExtension = '-test';
    }

    let countryCode = 'en';
    if (trunkEnvironment.hasTrunkAccess || trunkEnvironment.disabled) {
      countryCode = trunkEnvironment.tenantCountryCode ?? '';
    }

    if (!!countryCode) {
      return i18next.t('partner_media_link', {
        countryCode: `${countryCode.toLowerCase()}`,
        envExtension
      });
    }

    return '';
  }
}
